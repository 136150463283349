up.compiler('[searchable-bookings]', function(element) {

  let inputField = element.querySelector('[searchable-bookings--field]')
  let roomCards = element.querySelectorAll('[searchable-bookings--room]')
  let resetButton = element.querySelector('[searchable-bookings--reset]')

  inputField.addEventListener('input', ( ) => {
    let query = inputField.value.toLowerCase().split(' ')

    roomCards.forEach( (roomCard) => {
      let roomName = roomCard.getAttribute('searchable-bookings--room').toLowerCase()
      let occupancyRows = roomCard.querySelectorAll('[searchable-bookings--occupancy]')
      let atLeastOneVisibleRow = false

      occupancyRows.forEach( (occupancy) => {
        let userName = occupancy.getAttribute('searchable-bookings--occupancy').toLowerCase()
        let isMatch = query.every((queryToken) => userName.includes(queryToken) || roomName.includes(queryToken))

        if (isMatch) {
          atLeastOneVisibleRow = true
          occupancy.classList.remove('d-none')
        } else {
          occupancy.classList.add('d-none')
        }
      })

      roomCard.classList.toggle('d-none', !atLeastOneVisibleRow)
    })

  })

  resetButton.addEventListener('click', () => {
    inputField.value = ''
    up.emit(inputField, 'input')
    inputField.focus()
  })

  up.on('up:fragment:inserted', (_event, fragment) => {
    if (fragment.matches('[up-poll]')) {
      roomCards = fragment.querySelectorAll('[searchable-bookings--room]')
      up.emit(inputField, 'input')
    }
  })

})
