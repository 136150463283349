import Timeout from '../util/timeout'
import { inTestEnvironment } from '../util/environment'

up.compiler('.flashes--flash.alert', (element) => {
  if (element.closest('.layoutless')) {
    // Alerts should not be interactable on "layoutless" pages
    return
  }

  function removeAutomatically() {
    if (inTestEnvironment()) {
      // We don't want to remove flashes automatically in tests because
      // - tests might not see flashes if they are slow,
      // - it could cause flickering behavior if flashes cover elements only sometimes.
      return
    } else {
      remove()
    }
  }

  function remove() {
    timeout.clear()
    up.animate(element, 'move-to-top').then(() => { element.remove() })
  }


  let closeButton = up.element.createFromHTML('<button type="button" class="close"><span aria-label="Hinweis schließen"><span aria-hidden="true">×</span></button>')
  element.classList.add('alert-dismissible')
  closeButton.addEventListener('click', remove)
  element.appendChild(closeButton)

  let timeout = new Timeout(removeAutomatically, 5000)
  element.addEventListener('mouseenter', () => { timeout.pause() })
  element.addEventListener('mouseleave', () => { timeout.resume() })

  return remove
})
