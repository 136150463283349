import { inTestEnvironment } from '../util/environment'

up.compiler('.form-check', (element) => {
  const label = element.querySelector('label')
  const input = element.querySelector('input[type="checkbox"], input[type="radio"]')
  const type = input.type === 'checkbox' ? 'switch' : input.type

  if (label && input) {
    input.classList.add('custom-control-input')
    label.classList.add('custom-control-label')
    label.classList.remove('form-check-input')
    element.classList.add('custom-control', `custom-${type}`)
    if (inTestEnvironment()) input.classList.add('-test')
  }

  if (element.matches('.form-check-inline')) {
    element.classList.remove('form-check-inline')
    element.classList.add('custom-control-inline')
  }
})
