up.radio.config.hungrySelectors.push('meta[name="csrf-token"]')
up.radio.config.pollInterval = parseFloat(document.body.dataset.pollInterval || 60) * 1000

up.form.config.groupSelectors.unshift('.form-group')

up.viewport.config.revealSnap = 200

// When no target is given, update .content
up.fragment.config.mainTargets.unshift('.content')
up.history.config.restoreTargets = up.fragment.config.mainTargets
up.fragment.config.runScripts = true

// By default all links and forms will be handled by Unpoly.
// Individual links can opt out with [up-follow=false].
// Individual forms can opt out with [up-submit=false].
up.link.config.followSelectors.push('a[href]')
up.link.config.instantSelectors.push('a[href]')
up.link.config.noInstantSelectors.push('.btn')
up.link.config.preloadSelectors.push('a[href]')
up.form.config.submitSelectors.push('form')

up.layer.config.modal.dismissAriaLabel = 'Schließen'
up.layer.config.modal.dismissLabel = '×'

// Drawers should slide in from the right, like the hamburger menu.
up.layer.config.drawer.openAnimation = 'move-from-right'
up.layer.config.drawer.closeAnimation = 'move-to-right'

up.network.config.progressBar = true

// Only do this in development. This requires a `[data-environment="development"]` attribute on your `<body>` element.
// See https://makandracards.com/makandra/1433-detect-the-current-rails-environment-from-javascript-or-css
if (document.body.dataset.env === 'development') {
  // Returns true when your development server shows an error page.
  function isDevelopmentError(response) {
    return !response.ok && /Full backtrace|Better errors/.test(response.text)
  }

  // When the development server shows an error page, repeat the request as a full page load.
  up.on('up:fragment:loaded', function(event) {
    if (isDevelopmentError(event.response)) {
      event.preventDefault() // Prevent the fragment from being updated
      event.request.loadPage()
    }
  })
}

if (document.body.dataset.env === 'test') {
  // Disable animations globally so Capybara doesn't see duplicate elements during transitions
  up.motion.config.enabled = false
  // When revealing elements, don't animate the scrolling
  up.viewport.config.duration = 0
  up.network.config.badResponseTime = 800
  // high delay so preload is disabled in test
  up.link.config.preloadDelay = 10 * 1000
  up.network.config.concurrency = 1
}
