up.compiler('.is-invalid', (element) => {

  function onChange() {
    element.classList.remove('is-invalid')

    const message = element.parentElement.querySelector('.invalid-feedback')
    if (message) message.remove()
  }

  element.addEventListener('input', onChange, { once: true })

})
