up.compiler('.day-menu-dish-autocomplete', function(element) {
  const titleInput = element.querySelector('[name*=title]')
  const suggestionsContainer = element.querySelector('.day-menu-dish-autocomplete--suggestions')

  function clearSuggestions() {
    // Empty container is hidden by CSS :empty rule
    suggestionsContainer.innerHTML = ''
  }

  function pickSuggestion(suggestion) {
    for (let prop of Object.keys(suggestion)) {
      if (['id', 'day_menu_id', 'created_at', 'updated_at'].includes(prop)) { continue } // ignore these attributes

      const value = suggestion[prop]
      let input

      if (['ingredients', 'recipe_style'].includes(prop)) {
        input = element.querySelector(`[id*=${value}]`)
        input.checked = 'checked'
      } else {
        input = element.querySelector(`[name*=${prop}]`)
      }

      input.value = value
    }
    clearSuggestions()
  }

  function renderSuggestions(suggestions) {
    clearSuggestions()
    for (let suggestion of suggestions) {
      const item = up.element.affix(suggestionsContainer, 'li.day-menu-autocomplete--suggestion', { text: suggestion.title })
      item.addEventListener('click', () => pickSuggestion(suggestion))
    }
  }

  return up.watch(titleInput, async function(query) {
    if (query === '') {
      clearSuggestions()
      return
    }

    const response = await up.request('/day_menus/dish_suggestions', { params: { query } })
    const suggestions = response.json
    renderSuggestions(suggestions)
  })
})
