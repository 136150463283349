function message(roomName) {
  return `Die Kollegen in "${roomName}" wünschen sich ein ruhiges Umfeld.\n
Wenn du heute viel sprechen oder telefonieren musst, wähle bitte einen anderen Raum.\n
Tisch in ${roomName} trotzdem buchen?`
}

up.compiler('[silent-room]', function(element) {

  if (up.element.booleanAttr(element, 'silent-room')) {

    let roomName = element.querySelector('[silent-room--name]').getAttribute('silent-room--name')
    let buttons = element.querySelectorAll('[silent-room--button]')

    buttons.forEach( (button) => {
      button.addEventListener('click', (event) => {
        if (!confirm(message(roomName))) { event.stopPropagation() }
      })
    })

  }

})
